/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/jquery/jquery@3.2.1/dist/jquery.min.js
 * - /npm/bootstrap@3.3.7/dist/js/bootstrap.min.js
 * - /gh/digitalBush/jquery.maskedinput@1.4.1/dist/jquery.maskedinput.min.js
 * - /npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js
 * - /npm/flexslider@2.7.2/jquery.flexslider.min.js
 * - /npm/owl.carousel@2.3.4/dist/owl.carousel.min.js
 * - /npm/slick-slider@1.8.2/slick/slick.min.js
 * - /npm/magnific-popup@1.1.0/dist/jquery.magnific-popup.min.js
 * - /npm/jarallax@1.10.7/dist/jarallax.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
